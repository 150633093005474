import { useState, useEffect, useRef } from 'react';
import { useLanguage } from '../../context/language';

export default () => {
  const language = useLanguage();
  const ref = useRef(null);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const onSubmit = async (e) => {
      e.preventDefault();

      if (submitting) {
        return false;
      }

      setSubmitting(true);
      setSuccess(false);
      setError(false);

      const form = ref.current.querySelector('form');
      const formId = form.querySelector('[name="_wpcf7"]').value;
      const values = new FormData(form);
      try {
        values.set('url', window.location.href);
      } catch (err) {
        // if browser does not support .set
        values.append('url', window.location.href);
      }

      const apiUrl = process.env.GATSBY_API_URL;
      const postUrl = `${apiUrl}/${language}/wp-json/contact-form-7/v1/contact-forms/${formId}/feedback`;

      let result;
      try {
        result = await fetch(postUrl, {
          method: 'POST',
          body: values,
        });
      } catch (err) {
        setSubmitting(false);
        setError(err.message);
        return;
      }

      let response;
      try {
        response = await result.json();
      } catch (err) {
        setSubmitting(false);
        setError(err.message);
        return;
      }

      setSubmitting(false);

      if (response.status === 'validation_failed') {
        setError(response.message);
        return;
      }

      if (response.status === 'mail_failed') {
        setError(response.message);
        return;
      }

      if (response.status === 'mail_sent') {
        setSuccess(response.message);
        form.reset();
        // return;
      }
    };

    const form = ref.current.querySelector('form');
    form.onsubmit = onSubmit;
    form.removeAttribute('action');
    form.removeAttribute('method');
    form.removeAttribute('novalidate');

    [...form.querySelectorAll('[aria-required]')].forEach((node) => {
      // eslint-disable-next-line no-param-reassign
      node.required = true;
    });

    [...form.children].forEach((node) => {
      const input = node.querySelector('[name]');
      if (input) {
        node.classList.add(input.nodeName);
      }
      const submit = node.querySelector('[type="submit"]');
      if (submit) {
        node.classList.add('SUBMIT');
      }
    });
  }, [language, submitting]);

  return {
    ref,
    submitting,
    error,
    success,
  };
};
