import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { useI18n } from 'gatsby-theme-wordpress/src/context/language';

import Layout from '../../layouts';
import ContactForm from '../../components/contact-form';
import Container from '../../components/container';
import Content from '../../components/content';

export const pageQuery = graphql`
  query ContactPage($id: String) {
    page: wordpressPage(id: { eq: $id }) {
      yoast_meta {
        name
        content
        property
      }
      yoast_title
      yoast_json_ld {
        wordpress__context
        wordpress__graph {
          wordpress__type
          wordpress__id
          name
          description
          width
          height
          inLanguage
          datePublished
          dateModified
          isPartOf {
            wordpress__id
          }
          primaryImageOfPage {
            wordpress__id
          }
          url
        }
      }

      title
      content
      acf {
        form: contact_form
        locations: contact_locations {
          title
          address {
            line
          }
          phonenumbers {
            phonenumber
          }
          fax
          email
          url
          show_on_contact_page
          image {
            localFile {
              name
              childImageSharp {
                fixed(width: 160, cropFocus: ENTROPY) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ContactTemplate = ({ data }) => {
  const { page } = data;
  const i18n = useI18n();

  return (
    <Layout page={page}>
      <Container>
        <Wrapper>
          <Aside>
            {page.acf?.locations?.filter(Boolean)
              .filter(({ show_on_contact_page }) => show_on_contact_page)
              .map((location) => (
                <Location key={`${location.title}-${location.address?.[0]}`}>
                  <h4>{location.title}</h4>
                  <address>
                    {location.address?.map(({ line }) => (
                      <Fragment key={line}>
                        {line}
                        <br />
                      </Fragment>
                    ))}
                  </address>
                  <Img
                    fixed={location.image.localFile.childImageSharp.fixed}
                    alt={location.image.localFile.name}
                  />
                  <table>
                    <tbody>
                      {location.phonenumbers?.map(({ phonenumber }) => (
                        <tr>
                          <td>{i18n?.abbr?.tel}:</td>
                          <td>{phonenumber}</td>
                        </tr>
                      ))}
                      {location.fax && (
                        <tr>
                          <td>{i18n?.abbr?.fax}:</td>
                          <td>{location.fax}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <table>
                    <tbody>
                      {location.email && (
                        <tr>
                          <td>{i18n?.abbr?.email}:</td>
                          <td><a href={`mailto:${location.email}`}>{location.email}</a></td>
                        </tr>
                      )}
                      {location.url && (
                        <tr>
                          <td>{i18n?.abbr?.website}:</td>
                          <td><a href={location.url}>{location.url.replace('https://', '')}</a></td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Location>
              ))}
          </Aside>
          <Main>
            <StyledContent>
              <h3>{page.title}</h3>
              {page.content && (
                <div dangerouslySetInnerHTML={{ __html: page.content }} />
              )}
            </StyledContent>
            {page.acf?.form && <ContactForm htmlString={page.acf.form} />}
          </Main>
        </Wrapper>
      </Container>
    </Layout>
  );
};

const StyledContent = styled(Content)`
  table {
    margin-top: 1rem;
    td:first-child {
      padding-right: .2rem;
    }
  }
`;

const Location = styled(StyledContent)`
  margin: 2rem 0;
  border-left: .2rem solid ${(props) => props.theme.primaryColor};
  padding: 0 0 0 1rem;
`;

const Aside = styled.aside``;
const Main = styled.div``;

const Wrapper = styled.div`
  padding: ${(props) => props.theme.sectionSpace} 0;
  display: grid;
  align-items: flex-start;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${(props) => props.theme.grid.columnGap};
  grid-auto-rows: auto;
  ${Aside} {
    grid-column: 1 / 4;
    grid-row: 2;
    margin-top: ${(props) => props.theme.sectionSpace};
    @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
      margin-top: 0;
      grid-column: 1 / 2;
      grid-row: 1;
    }
  }
  ${Main} {
    grid-column: 1 / 4;
    grid-row: 1;
    @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
      grid-column: 2 / 4;
      grid-row: 1;
    }
  }
`;

export default ContactTemplate;
