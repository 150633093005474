import React from 'react';
import styled, { css } from 'styled-components';
import useForm from 'gatsby-theme-wordpress/src/components/form/useForm';
import { useI18n } from 'gatsby-theme-wordpress/src/context/language';

const Alert = styled.span`
  display: block;
  padding: 15px 0;
  margin-left: 0;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    width: auto;
    margin-left: calc(11.11rem + ${(props) => props.theme.grid.columnGap});
  }
  ${(props) => props.success && css`
    color: green;
  `}
  ${(props) => props.error && css`
    color: red;
  `}
`;

const FormWrapper = styled.div`
  form {
    padding-top: 1rem;
    > p {
      width: 100%;
    }
    .INPUT,
    .TEXTAREA {
      label {
        display: grid;
        grid-auto-rows: auto;
        grid-template-columns: 1fr;
        padding-bottom: .8rem;
        align-items: center;
        @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
          grid-gap: ${(props) => props.theme.grid.columnGap};
          grid-template-columns: 11.11rem 3fr;
        }
        input,
        textarea {
          width: 100%;
          box-sizing: border-box;
          border: 1px solid ${(props) => props.theme.gray1};
          padding: 1rem;
          margin-top: .4rem;
          @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
            margin-top: 0;
          }
        }
        input {
          height: 3.333rem;
        }
      }
    }
    .TEXTAREA {
      label {
        align-items: flex-start;
        @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
          padding-top: 1rem;
        }
        textarea {
          height: 8.889rem;
          @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
            margin-top: -1rem;
          }
        }
      }
    }
    .SUBMIT {
      margin-left: 0;
      @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
        width: auto;
        margin-left: calc(11.11rem + ${(props) => props.theme.grid.columnGap});
      }
      input {
        color: ${(props) => props.theme.white};
        background-color: ${(props) => props.theme.primaryColor};
        border: 1px solid ${(props) => props.theme.primaryColor};
        border-radius: ${(props) => props.theme.borderRadius};
        padding: 1rem 2rem;
        min-width: 12.22rem;
        width: 100%;
        cursor: pointer;
        @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
          width: auto;
        }
        &:hover {
          opacity: .9;
        }
      }
    }
  }
`;

const ContactForm = ({
  className,
  htmlString,
}) => {
  const i18n = useI18n();
  const {
    ref,
    submitting,
    error,
    success,
  } = useForm();

  return (
    <section
      className={className}
    >
      <FormWrapper
        submitting={submitting}
        ref={ref}
        dangerouslySetInnerHTML={{ __html: htmlString }}
      />
      {error && (
        <Alert error>{i18n?.formError}</Alert>
      )}
      {success && (
        <Alert success>{i18n?.formSuccess}</Alert>
      )}
    </section>
  );
};

export default styled(ContactForm)``;
